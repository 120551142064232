.product-desc {
	padding: $shop-desc-padding 0;
}

.product-title { margin-bottom: 7px; }

.product-title h3 {
	margin: 0;
	font-size: $shop-title-font-size;
}

.product-title h3 a,
.single-product .product-title h2 a { color: $shop-title-color; }
.product {
	transition: background-color .3s;
	cursor: pointer;
	&:hover {
		text-decoration: underline !important;
		background: #f9f9f9;
	}
}
.product-image {
	height: 300px;
	display: block;
	position:relative;
	&__img {
		max-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&--large {
		height:400px;
	}
	@include media-breakpoint-down(lg)
	{
		&--large {
			height:300px;
		}
	}
}

.product-carousel {
	.owl-carousel .owl-stage {
		display: flex;
	}

	.owl-carousel .owl-item img {
		width: auto;
		max-height: 400px;
	}
	.owl-next, .owl-prev {
		font-size: 2rem !important;
	}
	.owl-item {
		justify-content: center;
		align-self: center;
		display: flex;
		align-items: flex-start;
	}
	.owl-carousel-full .owl-nav [class*=owl-] {
		background: $owl-full-product-nav-bgcolor;
		color: $owl-full-product-nav-color;
	}
}