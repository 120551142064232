
/*-----------------------------------------------------------------------------------

	Shortcodes: misc.scss

-----------------------------------------------------------------------------------*/

/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/

#infscr-loading,
#portfolio-ajax-loader {
	position: fixed;
	z-index: 20;
	top: 50%;
	left: 50%;
	width: 48px;
	height: 48px;
	margin: -24px 0 0 -24px;
	background-color: rgba(0,0,0,0.7);
	border-radius: 3px;
	line-height: 48px;
	font-size: 1.5rem;
	color: #FFF;
	text-align: center;
	img {
		display: none;
		width: 24px;
		height: 24px;
		margin: 12px;
	}
}

#portfolio-ajax-loader {
	display: none;
	img { display: block; }
}

.page-load-status {
	position: relative;
	display: none;
	padding: 30px 0;
	.css3-spinner-ball-pulse-sync > div { background-color: #333; }
}


/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/

blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 1.125rem;
	border-left: 5px solid #EEE;
	p { margin-bottom: 15px; }
	&.float-left {
		max-width: 300px;
		margin: 5px 20px 10px 0;
		padding-right: 0;
	}
	&.float-right {
		max-width: 300px;
		margin: 5px 0 10px 20px;
		padding-left: 0;
	}
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid #EEE;
	border-left: 0;
	text-align: right;
	&::before {
		content: "\e7ae";
		left: auto;
		right: 0;
	}
}

.quote {
	border: none !important;
	position: relative;
	p { position: relative; }
	&::before {
		font-family: 'font-icons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		content: "\e7ad";
		position: absolute;
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 42px;
		top: 0;
		left: 0;
		color: #EEE;
	}
}


/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/


.bg-overlay,
.bg-overlay-bg,
.bg-overlay-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
}

.bg-overlay {
	z-index: 5;
	[data-hover-animate]:not(.animated) {
		opacity: 0;
	}

	&-content {
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		padding: 20px;
	}

	&-bg {
		background-color: rgba(255,255,255,0.85);
	}
}

.dark .bg-overlay-bg,
.bg-overlay-bg.dark {
	background-color: rgba(0,0,0,0.5);
}


.text-overlay-mask {
	opacity: 0.85;
	top: auto;
	bottom: 0;
	height: auto;
	padding: 40px 15px 15px;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.85))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.overlay-trigger-icon {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin: 0 0.25rem;
	font-size: 1.125rem;
	text-align: center;
	border-radius: 50%;
	-webkit-backface-visibility: hidden;
	transition: all .3s ease;
}

.overlay-trigger-icon.size-sm {
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin: 0 0.5rem;
	font-size: 0.875rem;
}

.overlay-trigger-icon.size-lg {
	width: 64px;
	height: 64px;
	line-height: 64px;
	margin: 0 0.5rem;
	font-size: 1.5rem;
}


/* ----------------------------------------------------------------
	Google Maps
-----------------------------------------------------------------*/


.gmap {
	position: relative;
	width: 100%;
	height: 450px;
	img { max-width: none !important; }
}

.gm-style .gm-style-iw h3 span {
	font-size: inherit;
	font-family: inherit;
}

#map-overlay {
	position: relative;
	padding: 100px 0;
	.gmap {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
	}
}

/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/


#gotoTop {
	display: block;
	opacity:0;
	z-index: 599;
	position: fixed;
	width: $gotoTop-size;
	height: $gotoTop-size;
	background-color: #333;
	background-color: $gotoTop-bg;
	font-size: $gotoTop-icon-size;
	line-height: 36px;
	text-align: center;
	color: $gotoTop-icon-color;
	top: auto;
	left: auto;
	right: $gotoTop-position-boxed-right;
	bottom: $gotoTop-position-boxed-bottom;
	cursor: pointer;
	border-radius: $gotoTop-border-radius;
	transition: opacity .2s linear;
	-webkit-transition: opacity .2s linear;
	-o-transition: opacity .2s linear;

	body:not(.device-touch) & {
		transition: opacity .2s linear, background-color .2s linear;
		-webkit-transition: opacity .2s linear, background-color .2s linear;
		-o-transition: opacity .2s linear, background-color .2s linear;
	}

	.stretched & {
		right: $gotoTop-position-boxed-right;
		bottom: $gotoTop-position-boxed-bottom;
	}

	&:hover { background-color: $gotoTop-hover-color; }

	@include media-breakpoint-down(sm) {
		@if $gotoTop-hidden-sm == true {
			display: none !important;
		} @else {
			display: block !important;
		}
	}

	&.gototop-active {
		opacity: 1;
	}
}


/* ----------------------------------------------------------------
	GDPR Settings
-----------------------------------------------------------------*/

.gdpr-settings {
	position: fixed;
	max-width: calc(100% - 2rem);
	margin-bottom: 1rem;
	border-radius: 4px;
	top: auto;
	bottom: 0;
	left: 1rem;
	opacity: 0;
	padding: 3rem;
	z-index: 999;
	background-color: #EEE;
	transition: all .3s ease;
	&-sm {
		max-width: 30rem;
		margin-right: 1rem;
	}
	&-sm#{&}-right {
		left: auto;
		right: 1rem;
		margin-right: 0;
		margin-left: 1rem;
	}
	&.dark {
		background-color: #111;
	}
}
