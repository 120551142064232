/* ----------------------------------------------------------------
	Canvas: Non-Profit
-----------------------------------------------------------------*/

/* Root Settings
-----------------------------------------------------------------*/
:root {
  --themecolor: #C6C09C;
  --themecolorrgb: 198, 192, 156;
}

.content-wrapper {
  min-height: calc(100vh - 83px);
}

/* Custom Header Size
-----------------------------------------------------------------*/
.header-size-custom #logo img {
  height: 64px;
}

@media (min-width: 992px) {

  .header-size-custom .header-wrap-clone {
    height: calc(70px + 1px);
  }

  #header.header-size-custom + .include-header {
    margin-top: calc(-70px - 2px);
  }

  .header-size-custom .menu-container > .menu-item > .menu-link {
    padding-top: 24px;
    padding-bottom: 24px;
  }

}

/* Slider
-----------------------------------------------------------------*/
.swiper-container-horizontal > .swiper-scrollbar {
  top: auto;
  bottom: 180px;
  left: auto;
  right: 100px;
  width: 200px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}

.swiper-scrollbar-drag {
  background: #000
}

.slide-number {
  opacity: 1;
  bottom: 10px;
  text-align: left;
  right: auto;
}

.slide-number-current {
  top: auto;
  bottom: 0;
  font-size: 30px;
  font-weight: 700;
}

.slide-number span {
  margin-left: 20px;
  font-size: 16px;
}

.slide-number-total {
  font-size: 13px;
  line-height: 28px;
  left: 29px;
}

.swiper-navs {
  position: absolute;
  left: auto;
  right: 300px;
  bottom: 140px;
  top: auto;
  z-index: 99;
}

.slider-arrow-left,
.slider-arrow-right {
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 36px;
  height: 36px;
}

.slider-arrow-right {
  left: 45px;
}

.slider-arrow-left i,
.slider-arrow-right i {
  display: block;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 34px;
  margin: 0 auto;
  color: #000;
}

.slider-arrow-right i {
  margin-left: -1px;
}

.swiper-button-disabled {
  opacity: .5;
  cursor: default;
}

.dark .slider-arrow-left,
.dark .slider-arrow-right {
  border-color: rgba(255, 255, 255, 0.7);
}

.dark .swiper-container-horizontal > .swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.4);
}

.dark .swiper-scrollbar-drag {
  background: #FFF
}

.dark .slide-number,
.dark .slider-arrow-left i,
.dark .slider-arrow-right i {
  color: #FFF;
}

.slider-feature {
  position: relative;
  transform: translateY(-20%);
  z-index: 4;
}

.slider-feature a {
  color: #222;
  transition: all .2s ease;
}

.slider-feature a:hover {
  background-color: #C6C09C;
  background-color: var(--themecolor);
  transform: translateY(-2px);
  z-index: 1;
}

.slider-feature a i {
  position: relative;
  top: 4px;
  font-size: 20px;
  padding-right: 6px;
}

/* SVG Divider
-----------------------------------------------------------------*/
.svg-line {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  width: 100%;
  height: 20px;
}

.button-svg {
  background-image: url('../images/border.svg');
  background-repeat: no-repeat;
  background-position: center 120%;
  background-size: 100% 10px;
  color: #222;
  font-size: 16px;
}

/* Animated Setting
-----------------------------------------------------------------*/
.animated {
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Carousel
-----------------------------------------------------------------*/
.oc-desc {
  position: absolute;
  left: 25%;
  bottom: 16px;
  background-color: #FFF;
  padding: 15px;
  border-radius: 3px;
  height: 10%;
  width: 50%;
}


.oc-desc-text {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}


/* .owl-carousel .owl-item:hover .oc-desc, */
.owl-carousel .owl-item.active.center .oc-desc {
  opacity: 1;
  transform: scale(1);
}

.world-map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.counter-section [class^=col-]:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  opacity: .4;
  background-image: url('../images/divider-2.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.device-sm .counter-section [class^=col-]:nth-child(2)::after,
.device-md .counter-section [class^=col-]:nth-child(2)::after,
.device-xs .counter-section [class^=col-]::after {
  display: none;
}

/* Play Icon
-----------------------------------------------------------------*/
.play-video i {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 61px;
  border-radius: 50%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-size: 24px;
  color: #111;
  transition: all .3s ease;
  box-shadow: 0 0 1px 15px rgba(255, 255, 255, .1);
  -webkit-backface-visibility: hidden;
}

.play-video i.icon-small {
  width: 40px;
  height: 40px;
  line-height: 41px;
  font-size: 16px;
  box-shadow: 0 0 1px 10px rgba(255, 255, 255, .1);
}

.play-video:hover i {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  background-color: #FFF;
}

.bg-color::before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 6px;
  opacity: .3;
  background-color: #C6C09C;
  background-color: var(--themecolor);
}

.bg-color.left::before {
  top: -10px;
  left: -10px;
}

/* Heading Block
-----------------------------------------------------------------*/
.heading-block h2 {
  font-size: 36px;
  letter-spacing: -1px;
}

/* Missions Gloals
-----------------------------------------------------------------*/
.mission-goals .feature-box {
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.15);
  padding: 25px 20px;
  border-radius: 10px;
}

/* Team/Volunteers
-----------------------------------------------------------------*/
.team-title {
  font-size: 17px;
}

.team-title small {
  display: block;
  font-size: 13px;
  color: #AAA;
}

.team-image img {
  border-radius: 6px
}

.team.full-border {
  border: 5px solid #C6C09C;
  border-color: var(--themecolor);
  border-radius: 4px;
}

/* Subscribe Section
-----------------------------------------------------------------*/
.subscribe-section .subscribe-section-target {
  cursor: pointer;
  padding: 35px 0;
}

.subscribe-icon {
  position: absolute;
  top: 0px;
  left: 50%;
  width: 120px;
  height: 120px;
  line-height: 110px;
  background-color: #C6C09C;
  background-color: var(--themecolor);
  border-radius: 50%;
  text-align: center;
  transform: translate(-50%, -45%);
}

.subscribe-icon i {
  font-size: 38px;
  text-align: center;
  color: #444;
  font-weight: 600;
}

.subscribe-button {
  opacity: 0;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) scale(0.5);
  transition: opacity .3s linear;
  z-index: 1001;
}

body:not(.nonprofit-success):not(.nonprofit-processing) .show .subscribe-button {
  opacity: 1;
  transform: translate(-50%, 50%) scale(1);
}

.nonprofit-loader.css3-spinner {
  background-color: rgba(198, 192, 156, 0.5);
  background-color: rgba(var(--themecolorrgb), 0.5);
}

.css3-spinner > div {
  background-color: #111;
}

#nonprofit-submitted,
.nonprofit-success #nonprofit,
body:not(.nonprofit-processing) .nonprofit-loader {
  display: none;
}

.subscribe-section-target .icon-arrow-down {
  transform: rotate(0deg);
  transition: transform .2s linear;
}

.subscribe-section-target:not(.collapsed) .icon-arrow-down {
  transform: rotate(180deg);
}

.subscribe-section .subscribe-icon i {
  transform: scale(1);
  transition: transform .25s ease;
}

.subscribe-section:hover .subscribe-icon i {
  transform: scale(1.2);
}

.nonprofit-success #nonprofit-submitted {
  display: block;
}

/* Border Form Design
---------------------------------------------------------------------------- */
.form-control.border-form-control {
  height: 48px;
  padding: 8px 4px;
  font-size: 19px;
  background-color: transparent !important;
  background-image: url('../images/divider-3.svg');
  background-repeat: no-repeat;
  background-position: bottom center;
  border: 0;
}

.form-control.border-form-control::-moz-placeholder {
  opacity: .4;
}

.form-control.border-form-control:-ms-input-placeholder {
  opacity: .4;
}

.form-control.border-form-control::-webkit-input-placeholder {
  opacity: .4;
}

/* Event Calendar
-----------------------------------------------------------------*/
.fc-calendar {
  background: #FFF;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.15);
}

.fc-calendar .fc-row > div.fc-today,
.fc-calendar .fc-row > div > div a, .fc-calendar .fc-row > div > div span {
  background-color: #C6C09C;
  background-color: var(--themecolor);
  box-shadow: none;
}

.fc-calendar .fc-row > div.fc-today > span.fc-date,
.fc-calendar .fc-row > div > div a:not(:hover),
.fc-calendar .fc-row > div > div span:not(:hover) {
  color: #222;
  text-shadow: none;
}

.fc-content {
  overflow-y: scroll !important;
}

.fc-calendar-event + .fc-calendar-event {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 12px;
  margin-top: 8px;
}

.fc-allday[datetime="true"] + .fc-starttime,
.fc-allday[datetime="true"] + .fc-starttime + .fc-endtime {
  display: none;
}

.fc-emptydate,
.fc-weekday {
  opacity: .07 !important;
}

.fc-past.fc-content .fc-calendar-event {
  opacity: .4;
  pointer-events: none;
}

.fc-past .fc-date,
.fc-past .fc-weekdays {
  opacity: .3;
}

/* Section Details
-----------------------------------------------------------------*/
.section-details p {
  color: #666;
}

.testi-content p {
  font-style: normal;
  padding-left: 30px;
  border-left: 3px solid rgba(198, 192, 156, 0.2);;
  border-left: 3px solid rgba(var(--themecolorrgb), 0.2);
}

.testimonials-carousel .owl-stage {
  padding: 5px 0;
}

.testimonials-carousel::before {
  content: '"';
  position: absolute;
  top: -20px;
  left: 22px;
  font-size: 60px;
  color: rgba(198, 192, 156, 0.4);;
  color: rgba(var(--themecolorrgb), 0.4);
  z-index: -1;
  font-family: "Segoe Print", "Times New Roman", Verdana;
}

.testi-meta {
  margin: 10px 0 0 30px;
}

/* Floating Contact
-----------------------------------------------------------------*/
.floating-contact-wrap {
  position: fixed;
  right: 50px;
  bottom: 120px;
  z-index: 299;
}

.floating-contact-wrap .floating-contact-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #C6C09C;
  background-color: var(--themecolor);
  color: #FFF;
  cursor: pointer;
  z-index: 3;
  color: #222;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: transform .3s ease;
}

.floating-contact-wrap .floating-contact-btn:hover,
.floating-contact-wrap.active .floating-contact-btn {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  background-color: #222;
  color: #FFF;
}

.floating-contact-wrap .floating-contact-btn .floating-contact-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 22px;
  -webkit-transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.floating-contact-wrap .floating-contact-btn .floating-contact-icon.btn-active,
.floating-contact-wrap.active .floating-contact-btn .floating-contact-icon {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.floating-contact-wrap.active .floating-contact-btn .floating-contact-icon.btn-active {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.floating-contact-wrap .floating-contact-box {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 380px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 1;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.07);
  -webkit-transform: scale(.01);
  transform: scale(.01);
  -webkit-transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -webkit-backface-visibility: hidden;
}

.floating-contact-wrap.active .floating-contact-box {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.floating-contact-wrap .css3-spinner {
  background-color: rgba(255, 255, 255, 0.8)
}

.floating-contact-wrap form {
  padding: 35px 30px;
}

#floating-contact-submitted,
.floating-contact-success #floating-contact,
.floating-contact-success .floating-contact-heading,
body:not(.floating-contact-processing) .floating-contact-loader {
  display: none;
}

.floating-contact-success #floating-contact-submitted {
  display: block;
}

/* Responsive Device more than 992px (.device-md >)
-----------------------------------------------------------------*/
@media (min-width: 992px) {

  .menu-container > .menu-item > .menu-link {
    font-weight: 500 !important;
    text-transform: none;
    letter-spacing: 0;
    font-size: 15px;
    transition: color .3s ease;
    color: #111 !important;
  }

  .menu-container:hover > .menu-item:not(:hover):not(.active) > .menu-link {
    color: #DDD !important;
  }

  .menu-container > .menu-item > .menu-link div::after {
    opacity: 0;
    content: '';
    position: absolute;
    background-image: url('../images/border.svg');
    background-repeat: no-repeat;
    background-position: center center;
    top: 50%;
    left: 50%;
    margin-top: 14px;
    width: 60%;
    height: 10px;
    background-size: 100% 10px;
    transform: translate(-50%, -50%);
    -webkit-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }

  .menu-container > .menu-item.active > .menu-link div::after,
  .menu-container > .menu-item:hover > .menu-link div::after {
    opacity: 1;
    width: 90%;
  }

  #header .menu-bg {
    width: 87px;
    height: 70px;
    background: #FFF;
  }

  #header .menu-bg::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background-color: #FFF;
    width: 100vw;
    z-index: -1;
  }

  #header .menu-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-left: 0px solid transparent;
    border-right-width: 70px;
    border-right-style: solid;
    border-right-color: transparent;
    border-bottom-width: 70px;
    border-bottom-style: solid;
    border-bottom-color: #C6C09C;
    border-bottom-color: var(--themecolor);
    z-index: 0;
  }


  .slider-feature {
    transform: translateY(-50%);
  }

  .heading-block h2 {
    font-size: 54px;
  }

  .feature-box .fbox-icon {
    width: 80px;
    height: 80px;
  }

  .feature-box h3 {
    font-size: 20px;
  }

  .oc-desc h3 {
    font-size: 22px;
  }

  .counter {
    font-size: 48px;
    letter-spacing: 1px;
  }

  .counter-section h3 {
    font-size: 2.6rem;
  }
}


@media (max-width: 767.98px) {
  .swiper-container-horizontal > .swiper-scrollbar {
    bottom: 140px;
    right: 30px;
    width: 140px;
  }

  .swiper-navs {
    bottom: 100px;
    right: 168px;
  }

  .floating-contact-wrap .floating-contact-box {
    width: 330px;
  }
}

@media (max-width: 1024px) {
  .oc-desc {
    position: absolute;
    left: 5%;
    bottom: 16px;
    background-color: #FFF;
    padding: 15px;
    border-radius: 3px;
    height: 10%;
    width: 90%;
  }
}

.feature-box {
  padding: 0 1.5rem;
}

select.language__select {
  &, &:active, &:focus {
    background-color: #382e25;
    border: none;
    color: #cecece;
  }
}

.min-vh-55 {
  min-height: 55vh !important;
}

#footer {
  color: $text-color-dark;
}

.contact__map-marker {
  display: flex;
  align-items: flex-start;
  width: 200px;
  color: var(--main-blue);

  .pin-icon {
    font-size: 2rem;
    color: $color;
  }

  .pin-text {
    font-size: 1.3em;
    margin-left: 10px;
  }

}

.search {
  &__button {
    margin: 0;
    padding: 0 22px;
  }
  &__input-group {
    max-width: 500px;
  }

  &__input {
    border-color: lighten($color, 50%);

  }
}
.breadcrumbs {
  a:hover {
    text-decoration: underline !important;
  }
}
.modal-content {
  border-radius: 0;
}

.medium-zoom-overlay {
  z-index: 9999;
}

.medium-zoom-image.medium-zoom-image--opened {
  z-index: 9999;
}

.toggle-d {
  i {
    font-size: 0.8rem;
    padding-right:0.5rem;
  }
}
button.toggle-d.btn:focus{
  box-shadow: none;
}

.parallax-overflow {
  height:300px;
}
@include media-breakpoint-down(md)
{
  .parallax-overflow {
    height:200px;
  }
}

.break-line-block {
  display: inline-block;
  vertical-align: top;
}
.product-subtitle {
  h6.small {
    margin-bottom: 0;
  }
}