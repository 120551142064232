// Initialize
@import "sass/variables";
@import "sass/mixins";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Core CSS
@import "sass/layouts";
@import "sass/typography";
@import "sass/utilities";


// Content Blocks
@import "sass/topbar";
@import "sass/header";
@import "sass/sliders";
@import "sass/content";
@import "sass/shop";
@import "sass/widgets";
@import "sass/pagetitle";
@import "sass/portfolio";


// Shortcodes
@import "sass/shortcodes";

// Footer
@import "sass/footer";


// Paginations
@import "sass/paginations";

@import "sass/colors";

@import "sass/main";

@import "sass/fonts.css";

@import "sass/dark.css";

@import "sass/font-icons.css";
@import "sass/swiper.css";