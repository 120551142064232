/* ----------------------------------------------------------------

	content.scss

-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	background-color: $body-bg;
	p { line-height: $line-height-content; }
	.content-wrap {
		position: relative;
		padding: $content-padding 0;
		overflow: hidden;
	}
	.container { position: relative; }
}

.grid-container,
.grid-inner {
	position: relative;
	overflow: hidden;
}

.grid-inner {
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

body:not(.device-touch) .grid-container {
	-webkit-transition: height .4s ease;
	-o-transition: height .4s ease;
	transition: height .4s ease;
}