@font-face {
	font-family: 'font-icons';
	src: url('font-icons.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
[class^="icon-"], [class*=" icon-"],
.side-header .primary-menu ul > li.sub-menu > a:after,
.docs-navigation ul ul li.current a:after {
	display: inline-block;
	font-family: 'font-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.icon-2x {
	font-size: 2em;
}
.icon-3x {
	font-size: 3em;
}
.icon-4x {
	font-size: 4em;
}
.icon-5x {
	font-size: 5em;
}
.icon-fw {
	width: 1.2857142857142858em;
	text-align: center;
}

.iconlist {
	padding-left: 0;
	margin-left: 0;
	list-style-type: none;
}

.iconlist li { position: relative; }

.iconlist ul {
	list-style-type: none;
	margin: 0.5rem 0 0.5rem 1.5rem;
}

.iconlist > li [class^="icon-"]:first-child, .iconlist > li [class*=" icon-"]:first-child {
	position: relative;
	display: inline-block;
	width: 1.75em;
	text-align: center;
	top: 1px;
	width: 14px;
	margin-right: 0.5rem;
}

.iconlist.iconlist-large { font-size: 16px; }

.iconlist.iconlist-large li { margin: 4px 0; }

.iconlist.iconlist-large > li [class^="icon-"], .iconlist.iconlist-large > li [class*=" icon-"] {
	width: 16px;
	margin-right: 5px;
}

.iconlist-color li i {
	color: #1ABC9C;
	color: var(--themecolor);
}

.icon-border {
	padding: .2em .25em .15em;
	border: solid 0.08em #eeeeee;
	border-radius: .1em;
}
.icon.pull-left {
	margin-right: .3em;
}
.icon.pull-right {
	margin-left: .3em;
}
.icon-spin {
	-webkit-animation: spin 2s infinite linear;
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
	}
}
@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(359deg);
	}
}
@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
.icon-rotate-90 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.icon-rotate-180 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.icon-rotate-270 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	-webkit-transform: rotate(270deg);
	-moz-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	transform: rotate(270deg);
}
.icon-flip-horizontal {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
	-webkit-transform: scale(-1, 1);
	-moz-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.icon-flip-vertical {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
	-webkit-transform: scale(1, -1);
	-moz-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	transform: scale(1, -1);
}
.icon-stacked {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle;
}
.icon-stacked-1x,
.icon-stacked-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}
.icon-stacked-1x {
	line-height: inherit;
}
.icon-stacked-2x {
	font-size: 2em;
}
.icon-inverse {
	color: #ffffff;
}

.icon-angle-up:before {
	content: "\e7a6";
}
.icon-map-marker-alt:before {
	content: "\eac2";
}
.icon-search1:before {
	content: "\e618";
}
.icon-minus:before {
	content: "\eadd";
}
.icon-plus:before {
	content: "\eb1f";
}