$portfolio-prefix: portfolio;
.#{$portfolio-prefix} {
	position: relative;
	&-item {
		position: relative;
		.#{$portfolio-prefix}-reveal & { overflow: hidden; }
		.#{$portfolio-prefix}-image {
			position: relative;
			overflow: hidden;
		}

		.#{$portfolio-prefix}-image,
		.#{$portfolio-prefix}-image > a,
		.#{$portfolio-prefix}-image img {
			display: block;
			width: 100%;
			height: auto;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	&-rounded &-image {
		border-radius: $grid_filter-border-radius + 1;
		overflow: hidden;
	}

	body:not(.device-touch) & {
		-webkit-transition: height .4s linear;
		-o-transition: height .4s linear;
		transition: height .4s linear;
	}
}

@include media-breakpoint-up(md) {
	.#{$portfolio-prefix}-reveal {
		.#{$portfolio-prefix}-image img,
		.bg-overlay {
			-webkit-transition: all .3s ease-in-out;
			-o-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
		}
	}
}


/* Portfolio - Item Title
-----------------------------------------------------------------*/

.#{$portfolio-prefix}-desc {
	position: relative;
	z-index: 6;
	padding: $Portfolio_desc-padding;
	h3 {
		margin: 0;
		padding: 0;
		font-size: $Portfolio_desc-title-size;
		.col-md-9 & {
			font-size: decimal-round($Portfolio_desc-title-size / 1.1111, 3);
		}

		a {
			color: darken($text-color, 13.33);
			&:hover {
				color: $theme-color;
			}
		}
	}
	span {
		display: block;
		.col-md-9 & {
			font-size: decimal-round($Portfolio_desc-title-size / 1.428, 3);
		}
	}

	span,
	span a {
		color: lighten($text-color, 26.67);
	}

	span a:hover {
		color: #000;
	}

	.desc-lg  & {
		padding: 20px 8px;
		h3 {
			font-size: decimal-round($Portfolio_desc-title-size / .8333, 3);
		}

		span {
			font-size: 110%;
		}
	}

	.desc-sm & {
		padding: $container-padding-x 5px;
		h3 {
		font-size: decimal-round($Portfolio_desc-title-size * .8, 3);
		}

		 span {
			font-size: 90%;
		}
	}
	.#{$portfolio-prefix}.no-gutters & {
		padding-left: $container-padding-x;
		padding-right: $container-padding-x;
	}

	.desc-sm.no-gutters & {
		padding-left: $container-padding-x - 5px;
		padding-right: $container-padding-x - 5px;
	}

	.#{$portfolio-prefix}-reveal & {
		background-color: #FFF;
		top: -100%;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		@include media-breakpoint-up(md) {
			-webkit-transition: all .3s ease-in-out;
			-o-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
		}
		@include media-breakpoint-down(sm) {
			display: none !important;
		}
	}
}